<template>
  <div style="width: 100% ;padding: 10px">
    <div v-if="searchId<=0" style="margin: 10px 0 ;display: flex">
      <div class="block" >
        <!--            <span class="demonstration">请选择时间</span>-->
        <el-date-picker
            v-model="timeSpase"
            type="daterange"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="select">
        </el-date-picker>

        <el-select v-model="justRuKu" @change="tableDataShowChange" placeholder="">
          <el-option
              v-for="item in rukuCheck"
              :key="item"
              :label="item"
              :value="item">
          </el-option>
        </el-select>
        <el-input style="width: 200px" v-model="selectKey"></el-input>
        <el-button @click="tableDataShowChange">筛选</el-button>
        <el-button @click="selectKey='',tableDataShowChange()">重置</el-button>
      </div>
    </div>
    <el-table
        :data="tableDataShow"
        border
        :row-style="caozuoCheck"
        style="width: 90%">
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-table
              :data="props.row.scriapList"
              border>
            <el-table-column
                prop="noid"
                v-if="false"
                label="废件id">
            </el-table-column>
            <el-table-column
                prop="id"
                v-if="false"
                label="工件id">
            </el-table-column>
            <el-table-column
                prop="yuangongName"
                label="员工">
            </el-table-column>
            <el-table-column
                prop="scrapLabels"
                label="废件标签">
            </el-table-column>
            <el-table-column
                prop="scrapNumbers"
                label="废件数量">
            </el-table-column>
            <el-table-column
                prop="scrapNotes"
                label="备注">
              <template slot-scope="scope" >
                <el-button size="small" type="text" @click="scrapNotesUp = true , form.anInt = scope.row.noid , form.string = scope.row.scrapNotes">
                  {{ scope.row.scrapNotes }}</el-button>
              </template>
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                width="100">
              <template slot-scope="scope" >
                <el-button size="small" type="text" v-if="scope.row.caozuo == null" @click="feijianCaozuo(scope.row)">修改</el-button>
                <el-button size="small" type="text" v-if="scope.row.caozuo != null" @click="feijianCaozuo(scope.row)">
                  {{ scope.row.caozuo }}</el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column
          prop="gongjianid"
          v-if="false"
          label="工件id">
      </el-table-column>
      <el-table-column
          prop="xinghao"
          label="型号">
      </el-table-column>
      <el-table-column
          prop="tuzhihao"
          label="图纸号">
      </el-table-column>
      <el-table-column
          prop="changshang"
          label="厂商">
      </el-table-column>
      <el-table-column
          prop="intime"
          label="入厂时间">
      </el-table-column>
      <el-table-column
          prop="outtime"
          label="完工时间">
      </el-table-column>
      <el-table-column
          prop="scriapNum"
          label="废件数量">
      </el-table-column>
    </el-table>
    <div v-if="searchId<=0" style="margin: 10px 0">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[5, 10, 20]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
    <!--  废件标签编辑弹窗  -->
    <el-dialog :before-close="handleClose" :visible.sync="scrapNotesUp" width="50%">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="备注修改">
          <el-input v-model="form.string"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="scrapNotesUpDate(form)">保存</el-button>
          <el-button @click="close">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!--  弹窗  -->
    <el-dialog :before-close="handleClose" :visible.sync="feijiancaozuo" width="50%">
      <el-descriptions title="废件信息">
        <el-descriptions-item label="工件id">{{ feijiancaozuofrom.id }}</el-descriptions-item>
        <el-descriptions-item label="员工">{{ feijiancaozuofrom.yuangongName }}</el-descriptions-item>
        <el-descriptions-item label="废件标签">{{ feijiancaozuofrom.scrapLabels }}</el-descriptions-item>
        <el-descriptions-item label="废件数量">{{ feijiancaozuofrom.scrapNumbers }}</el-descriptions-item>
        <el-descriptions-item label="备注">{{ feijiancaozuofrom.scrapNotes }}</el-descriptions-item>
        <el-descriptions-item label="废件工序">{{ feijiancaozuofrom.gongxu }}</el-descriptions-item>
      </el-descriptions>
      <div class="block">
        <span class="demonstration">废件处理方式</span>
        <el-cascader
            v-model="chulifangshi"
            :options="options"
            :show-all-levels="false"
            :props="{ expandTrigger: 'hover' }">
        </el-cascader>
        <el-select v-model="process" v-if="tf.includes(chulifangshi[chulifangshi.length-1])" multiple placeholder="请选择工序">
          <el-option
              v-for="item in feijianGongxu"
              :key="item"
              :label="item"
              :value="item">
          </el-option>
        </el-select>
        <el-button size="small" type="text" v-if="process.length == 1" @click="processAddToEnd">至末尾</el-button>
        <el-button type="primary" @click="feijianSave">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import request from "@/utils/request";

export default {
  props:{
    searchId: {
      type:Number,
      default: 0
    },
  },
  data() {
    return {
      search:'',
      currentPage4:1,
      tf: ['本批次返修','下批次补加工'],
      rukuCheck: ['仅筛选未入库工件','仅筛选已入库工件','显示所有工件'],
      justRuKu: "仅筛选未入库工件",//仅筛选已入库工件
      size: 10,
      total:10,
      tableData: [],
      tableDataShow: [],
      feijiancaozuofrom: {},//废件操作弹窗
      feijiancaozuo: false,//废件操作弹窗
      chulifangshi: "",//废件操作
      feijianGongxu: [],//废件工序列标
      process: [],//废件已选工序列标
      options: [
          {
        value: '回用',
        label: '回用',
        children: [
            {
          value: '本批次返修',
          label: '本批次返修',
        },{
          value: '转入下批次',
          label: '转入下批次',
          children: [{
            value: '下批次重新加工',
            label: '下批次重新加工'
          }, {
            value: '下批次补加工',
            label: '下批次补加工'
          }]
        }]
      }, {
        value: '退货',
        label: '退货',
      }, {
        value: '不良品',
        label: '不良品',
      }],//操作列表
      scrapNotesUp: false,
      form: {
        string: "",
        anInt: 0
      },
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      timeSpase: '',

      selectKey: ""
    }
  },
  //加载表格
  mounted() {
    this.timeSpase = [(new Date()).getTime() - 3600 * 1000 * 24 * 30 , new Date()]
    this.select()
  },
  methods:{
    //整表查询
    select() {
      if(this.searchId > 0){
        request.post("/NonConformingProductsById",this.searchId).then(res => {
          if (res.code == "200"){
            /*this.$notify({
              title: '成功',
              message: '查询成功',
              type: 'success'
            });*/
            this.tableData = res.data
            this.tableDataShowChange()
            let errorList = []
            res.data.forEach(item => {
              try{
                errorList.push(...item.scriapList.filter(item=>(item.caozuo === undefined || item.caozuo === null || item.caozuo === "")))
              } catch (e) {}
            })
            this.$nextTick(()=>{
              this.$emit('errorList',errorList.length)//触发transfer方法，this.user 为向父组件传递的数据
            })
          }else {
            this.$notify.error({
              title: '错误',
              message: res.code + ":" + res.msg
            });
          }
        })
      } else {
        request.post("/NonConformingProducts",this.timeSpase).then(res => {
          if (res.code == "200"){
            /*this.$notify({
              title: '成功',
              message: '查询成功',
              type: 'success'
            });*/
            this.tableData = res.data
            this.tableDataShowChange()
          }else {
            this.$notify.error({
              title: '错误',
              message: res.code + ":" + res.msg
            });
          }
        })
      }
    },
    scrapNotesUpDate(form) {
      request.post("/scrapNotesUpDate",form).then(res => {
        if (res.code == "200"){
          this.$notify({
            title: '成功',
            message: '保存成功',
            type: 'success'
          });
          this.scrapNotesUp = false
          this.form = {string: "", anInt: 0}
          this.tableDataShow = []
          this.select()
        }else {
          this.$notify.error({
            title: '错误',
            message: res.code + ":" + res.msg
          });
        }
      })
    },
    feijianSave() {
      let que = {
        id: this.feijiancaozuofrom.noid,
        processList: this.process,
        act: this.chulifangshi[this.chulifangshi.length-1]
      }
      request.post("/FeijianActSave",que).then(res => {
        if (res.code == "200"){
          this.$notify({
            title: '成功',
            message: '保存成功',
            type: 'success'
          });
          this.feijiancaozuo = false
          this.tableDataShow = []
          this.select();
        }else {
          this.$notify.error({
            title: '错误',
            message: res.code + ":" + res.msg
          });
        }
      })
    },
    processAddToEnd() {
      let i = this.feijianGongxu.indexOf(this.process[0])
      for (let j = i+1; j < this.feijianGongxu.length; j++) {
        this.process.push(this.feijianGongxu[j])
      }
    },
    //关键字查询
    selectkey(){
      if (parseInt(this.search) >0 ){
        request.post("/connect/selectid",parseInt(this.search)).then(res =>{
          this.tableData = res.data
        })
      } else {
        request.post("/connect/selectkey",this.search).then(res =>{
          this.tableData = res.data
        })
      }
    },
    feijianCaozuo(row){
      request.post("/GetNoGongxuList",row.id).then(res =>{
        if (res.code == "200"){
          this.feijianGongxu = res.data
          this.feijiancaozuofrom.gongxu = row.gongxu
          this.feijiancaozuofrom.id = row.id
          this.feijiancaozuofrom.noid = row.noid
          this.feijiancaozuofrom.scrapLabels = row.scrapLabels
          this.feijiancaozuofrom.scrapNotes = row.scrapNotes
          this.feijiancaozuofrom.scrapNumbers = row.scrapNumbers
          this.feijiancaozuofrom.yuangongid = row.yuangongid
          this.feijiancaozuo = true
        }else {
          this.$notify.error({
            title: '错误',
            message: res.code + ":" + res.msg
          });
        }

      })
    },
    feijianCaozuoSave(row){

    },
    //重置
    remove(){
      this.search=''
      this.select();
    },
    handleSizeChange(val) {
      this.size = val;
      this.tableDataShowChange()

      //console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.currentPage4 = val
      this.tableDataShowChange()
      //console.log(`当前页: ${val}`);
    },
    tableDataShowChange(){
      let date=[]
      if (this.justRuKu === "仅筛选未入库工件"){
        date = this.tableData.filter(item=>!(item.outtime !== "" && item.outtime !== null && item.outtime !== undefined))
      } else if (this.justRuKu === "仅筛选已入库工件"){
        date = this.tableData.filter(item=>(item.outtime !== "" && item.outtime !== null && item.outtime !== undefined))
      } else if (this.justRuKu === "显示所有工件"){
        date = this.tableData
      }

      if (this.selectKey !== ""){
        date = date.filter(item => (item.xinghao.includes(this.selectKey) || item.tuzhihao.includes(this.selectKey)))

        this.total = date.length
      } else {
        date = date
      }


      this.total = date.length

      let _pageMax = Math.ceil(this.total/this.size)
      this.currentPage4 = this.currentPage4>_pageMax ? _pageMax :this.currentPage4;
      this.currentPage4 = this.currentPage4 < 1 ? 1 : this.currentPage4

      this.tableDataShow = date.slice((this.currentPage4-1)*this.size,this.currentPage4*this.size);
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {
          });
    },
    onSubmit() {
      console.log('submit!');
    },
    close() {
      this.scrapNotesUp = false
      this.form = {string: "", anInt: 0}
    },
    //检查是否有未处理废件
    caozuoCheck({row}){
      let list = row.scriapList
      if (list === null || list === undefined){
        return {}
      }
      //未处理
      let _list = list.filter(item => (item.caozuo === "" || item.caozuo === null || item.caozuo === undefined)
      )
      if (_list.length === 0){
        return {
          color: "green"  // 这个return的就是样式 可以是color 也可以是backgroundColor
        }
      } else {
        return {
          color: "red"  // 这个return的就是样式 可以是color 也可以是backgroundColor
        }
      }
    }
  }
}

</script>

<style scoped>

</style>